import { login, logout, changePWD } from '@/api/admin/index';
import { autoAPI } from '@/api/promiseApiHandler';
import user from './user';
import order from './order';

export default {
  namespaced: true,
  modules: { user, order },
  state: {
    userType: localStorage.getItem('userType'),
    isShowMobileMenu: false,
  },
  getters: {},
  mutations: {
    setUserType(state, val) {
      state.userType = val;
      localStorage.setItem('userType', val);
    },
    setIsShowMobileMenu(state, val) {
      state.isShowMobileMenu = val;
    },
  },
  actions: {
    login(_store, val) {
      return new Promise((resolve, reject) => {
        return autoAPI(login, val)
          .then((res) => {
            _store.commit('setUserType', res.data.type);
            resolve(res);
          })
          .catch(reject);
      });
    },
    logout(_store, val) {
      _store.commit('setUserType', '');
      return autoAPI(logout, val);
    },
    changePWD(_store, val) {
      return autoAPI(changePWD, val);
    },
  },
};
