<template>
  <div id="app" v-loading.fullscreen.lock="isLoading" :style="appStyleJudge">
    <DiGoHeader v-if="!isInConsolePage"></DiGoHeader>
    <router-view />
    <DiGoFooter v-if="!isInConsolePage"></DiGoFooter>
  </div>
</template>

<script>
  import DiGoHeader from '@/components/DiGoHeader';
  import DiGoFooter from '@/components/DiGoFooter';
  export default {
    name: 'App',
    components: {
      DiGoHeader,
      DiGoFooter,
    },
    data() {
      return {
        isInConsolePage: true,
      };
    },
    created() {
      this.resizeEvent();
      window.addEventListener('resize', this.resizeEvent);
    },
    watch: {
      $route: {
        handler: function (to) {
          if (to.matched.find((it) => it.name === 'Console')) {
            this.isInConsolePage = true;
          } else {
            this.isInConsolePage = false;
          }
        },
      },
    },
    methods: {
      resizeEvent() {
        if (window.innerWidth < 768) {
          this.$store.commit('setIsMobile', true);
        } else {
          this.$store.commit('setIsMobile', false);
        }
      },
    },
    computed: {
      isLoading() {
        return this.$store.state.isLoading;
      },
      appStyleJudge() {
        if (
          this.$store.state.isShowMobileMenu ||
          this.$store.state.isShowMobileTypeSelect ||
          this.$store.state.admin.isShowMobileMenu
        ) {
          return 'overflow: hidden;';
        } else {
          return '';
        }
      },
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.resizeEvent);
    },
  };
</script>

<style lang="scss">
  html {
    overflow-y: scroll;
    font-size: 16px;
    @media screen and (max-width: 800px) {
      font-size: 14px;
    }
    @media screen and (max-width: 500px) {
      font-size: 12px;
    }
    @media screen and (max-width: 300px) {
      font-size: 10px;
    }
    font-family: Helvetica Neue, Microsoft JhengHei, 'Microsoft YaHei', 'Hiragino Sans GB',
      'WenQuanYi Micro Hei', sans-serif;
  }
  body {
    padding: 0px !important;
  }

  html,
  body,
  #app {
    height: 100%;
  }
</style>
