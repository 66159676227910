import request from '@/utils/axios';
import { objToUrlQuery, getFormData } from '@/utils/axiosTools';
// 登入
export function login(param) {
  return request({
    url: `/api/console/login`,
    method: 'post',
    params: param,
  });
}

// 登出
export function logout(param) {
  return request({
    url: `/api/console/logout`,
    method: 'post',
  });
}

// 獲取用戶列表
export function getAccounts(param) {
  return request({
    url: `/api/console/accounts${objToUrlQuery(param)}`,
    method: 'get',
  });
}

// 獲取訂單列表
export function getOrders(param) {
  return request({
    url: `/api/console/orders${objToUrlQuery(param)}`,
    method: 'get',
  });
}

// 客服修改密碼
export function supportChangePWD(param) {
  const id = param.id;
  delete param.id;
  return request({
    url: `/api/console/accounts/${id}/change_password`,
    method: 'post',
    data: getFormData(param),
  });
}

// 用戶自己修改密碼
export function changePWD(param) {
  return request({
    url: `/api/console/change_password`,
    method: 'post',
    data: getFormData(param),
  });
}
