import { getOrders } from '@/api/admin/index';
import { autoAPI } from '@/api/promiseApiHandler';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getOrders(_store, val) {
      return autoAPI(getOrders, val);
    },
  },
};
