import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/LaTen',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
  },
  {
    path: '/laten',
    name: 'LaTen',
    component: () => import(/* webpackChunkName: "LaTen" */ '../views/Store/LaTen.vue'),
    children: [
      {
        path: 'list',
        name: 'LaTenStoreList',
        component: () =>
          import(
            /* webpackChunkName: "LaTenStoreList" */ '../views/Store/StoreList/LaTenStoreList.vue'
          ),
        children: [
          {
            path: 'detail',
            name: 'LaTenDetail',
            component: () =>
              import(
                /* webpackChunkName: "LaTenDetail" */ '../views/Store/StoreDetail/LaTenStoreDetail.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/yahoo',
    name: 'Yahoo',
    component: () => import(/* webpackChunkName: "Yahoo" */ '../views/Store/Yahoo.vue'),
    children: [
      {
        path: 'list',
        name: 'YahooStoreList',
        component: () =>
          import(
            /* webpackChunkName: "YahooStoreList" */ '../views/Store/StoreList/YahooStoreList.vue'
          ),
        children: [
          {
            path: 'detail',
            name: 'YahooDetail',
            component: () =>
              import(
                /* webpackChunkName: "YahooDetail" */ '../views/Store/StoreDetail/YahooStoreDetail.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/shopcart',
    name: 'ShopCart',
    component: () => import(/* webpackChunkName: "ShopCart" */ '../views/ShopCart/ShopCart.vue'),
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "Help" */ '../views/Help.vue'),
  },
  {
    path: '/profile',
    name: 'MyProfile',
    component: () => import(/* webpackChunkName: "MyProfile" */ '../views/MyProfile.vue'),
  },
  {
    path: '/console',
    name: 'Console',
    redirect: { name: 'ConsoleLogin' },
    component: () => import(/* webpackChunkName: "Console" */ '../views/Console/index.vue'),
    children: [
      {
        path: 'login',
        name: 'ConsoleLogin',
        component: () =>
          import(/* webpackChunkName: "ConsoleLogin" */ '../views/Console/Login.vue'),
      },
      {
        path: 'layout',
        name: 'ConsoleLayout',
        redirect: { name: 'ConsoleHome' },
        component: () =>
          import(/* webpackChunkName: "ConsoleLayout" */ '../views/Console/Layout/index.vue'),
        children: [
          {
            path: 'home',
            name: 'ConsoleHome',
            meta: {
              icon: 'el-icon-s-home',
              label: '首页',
            },
            component: () =>
              import(/* webpackChunkName: "ConsoleHome" */ '../views/Console/Home/index.vue'),
          },
          {
            path: 'ConsoleUserManagement',
            name: 'ConsoleUserManagement',
            meta: {
              icon: 'el-icon-s-custom',
              label: '用户管理',
            },
            component: () =>
              import(
                /* webpackChunkName: "ConsoleUserManagement" */ '../views/Console/UserManagement/index.vue'
              ),
            children: [
              {
                path: 'UserOperate',
                name: 'ConsoleUserManagement_UserOperate',
                meta: {
                  label: '用户操作',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "ConsoleUserManagement_UserOperate" */ '../views/Console/UserManagement/UserOperate/UserOperate.vue'
                  ),
              },
            ],
          },
          {
            path: 'ConsoleOrderManagement',
            name: 'ConsoleOrderManagement',
            meta: {
              icon: 'el-icon-s-order',
              label: '订单管理',
            },
            component: () =>
              import(
                /* webpackChunkName: "ConsoleOrderManagement" */ '../views/Console/OrderManagement/index.vue'
              ),
            children: [
              {
                path: 'SellOrder',
                name: 'ConsoleOrderManagement_SellOrder',
                meta: {
                  label: '卖家订单查询',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "ConsoleOrderManagement_SellOrder" */ '../views/Console/OrderManagement/SellOrder/SellOrder.vue'
                  ),
              },
              {
                path: 'CommodityPurchase',
                name: 'ConsoleOrderManagement_CommodityPurchase',
                meta: {
                  label: '商品采购查询',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "ConsoleOrderManagement_CommodityPurchase" */ '../views/Console/OrderManagement/SellOrder/SellOrder.vue'
                  ),
              },
            ],
          },
          {
            path: 'ConsoleSetting',
            name: 'ConsoleSetting',
            meta: {
              icon: 'el-icon-setting',
              label: '设置',
            },
            component: () =>
              import(/* webpackChunkName: "ConsoleSetting" */ '../views/Console/Setting/index.vue'),
            children: [
              {
                path: 'ChangePWD',
                name: 'ConsoleSetting_ChangePWD',
                meta: {
                  label: '修改密码',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "ConsoleSetting_ChangePWD" */ '../views/Console/Setting/ChangePWD.vue'
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 切換頁面時，回到最頂端
    return {
      x: 0,
      y: 0,
      // behavior: 'smooth',
    };
  },
});

export default router;
