<template>
  <div id="DiGoHeader">
    <div class="DiGoContainer">
      <div class="logo">
        <img
          src="@/assets/base/logo.png"
          style="cursor: pointer"
          alt=""
          @click="$router.push({ name: 'LaTen' })"
        />
      </div>

      <template v-if="!$store.state.isMobile">
        <div
          class="headerItem"
          :class="headerItemClassBind(['LaTen', 'LaTenStoreList', 'LaTenDetail'])"
        >
          <span style="cursor: pointer" @click="clickItemHandler('LaTen')">亚马逊购物网</span>
        </div>
        <div
          class="headerItem"
          :class="headerItemClassBind(['Yahoo', 'YahooStoreList', 'YahooDetail'])"
        >
          <span style="cursor: pointer" @click="clickItemHandler('Yahoo')">雅虎购物网</span>
        </div>
        <div class="headerItem" :class="headerItemClassBind('ShopCart')">
          <span style="cursor: pointer" @click="clickItemHandler('ShopCart')">购物车</span>
        </div>
        <div class="headerItem" :class="headerItemClassBind('Help')">
          <span style="cursor: pointer" @click="clickItemHandler('Help')">帮助中心</span>
        </div>
        <div class="headerItem">
          <template v-if="!isLogin">
            <div class="left">
              <div class="whiteBlock"> </div>
            </div>
            <div class="right">
              <div class="topBlock">
                <img src="@/assets/base/userIcon.png" alt="" />
                <span>Hi! 你好，请登录</span>
              </div>
              <div class="bottomBlock">
                <div class="loginBtn" @click="loginClick">登录</div>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="loginBlock">
              <div class="topIcon" @click="goProfileHandler">
                <img class="topIcon" src="@/assets/base/LuserIcon.png" alt="" />
              </div>
              <div class="bottomText" @click="goProfileHandler"> 我的帐户 </div>
            </div>

            <div class="loginBlock">
              <div class="topIcon" @click="logoutHandler">
                <img class="topIcon" src="@/assets/base/Llogout.png" alt="" />
              </div>
              <div class="bottomText" @click="logoutHandler"> 登出 </div>
            </div>
          </template>
        </div>
      </template>

      <template v-else>
        <div class="menuOpenBlock" @click="$store.commit('setShowMobileMenu', !isShowMobileMenu)">
          <img src="@/assets/base/menuOpen.png" alt="" />
        </div>
      </template>
    </div>

    <div
      class="MobileMenu"
      v-if="isShowMobileMenu"
      @click="$store.commit('setShowMobileMenu', false)"
    >
      <div class="MobileMenuContainer" @click.stop="() => {}">
        <div class="menuItem" @click="clickItemHandler('LaTen')"> 亚马逊购物网 </div>
        <div class="menuItemSplit"></div>
        <div class="menuItem" @click="clickItemHandler('Yahoo')"> 雅虎购物网 </div>
        <div class="menuItemSplit"></div>
        <div class="menuItem" @click="clickItemHandler('ShopCart')"> 购物车 </div>
        <div class="menuItemSplit"></div>
        <div class="menuItem" @click="clickItemHandler('Help')"> 帮助中心 </div>
        <div class="menuItemSplit"></div>
        <template v-if="isLogin">
          <div class="menuItem" @click="goProfileHandler"> 我的帐户 </div>
          <div class="menuItemSplit"></div>
          <div class="mobileLogout" @click="logoutHandler"> 登出 </div>
        </template>
        <template v-else>
          <div class="mobileNotLoginBlock">
            <img src="@/assets/base/userIconMobile.png" alt="" />
            <div class="plzLoginTip">Hi! 你好，请登录</div>
            <div class="loginBtn" @click="loginClick">登录</div>
          </div>
          <div class="menuItemSplit"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DiGoHeader',
    data() {
      return {};
    },
    computed: {
      isShowMobileMenu() {
        return this.$store.state.isShowMobileMenu;
      },
      isMobile() {
        return this.$store.state.isMobile;
      },
      isLogin() {
        return this.$store.state.loginData.isLogin;
      },
    },
    watch: {
      isMobile(val) {
        this.$store.commit('setShowMobileMenu', false);
      },
    },
    methods: {
      loginClick() {
        this.$store.commit('isHeaderClickLogin', !this.$store.state.isHeaderClickLogin);
        this.$store.commit('setShowMobileMenu', false);
        this.$router.push({ name: 'Login' });
      },
      logoutHandler() {
        this.$store.commit('setShowMobileMenu', false);
        this.$store.commit('setLoginData', false);
        this.$store.commit('isHeaderClickLogin', !this.$store.state.isHeaderClickLogin);
        this.$router.push({ name: 'Login' });
      },
      goProfileHandler() {
        this.$store.commit('setShowMobileMenu', false);
        this.$router.replace({ name: 'MyProfile' });
      },
      headerItemClassBind(clickName) {
        if (Array.isArray(clickName)) {
          if (clickName.indexOf(this.$route.name) !== -1) {
            return 'headerItemActive';
          } else {
            return '';
          }
        } else if (clickName === this.$route.name) {
          return 'headerItemActive';
        } else {
          return '';
        }
      },
      clickItemHandler(clickName) {
        this.$store.commit('setShowMobileMenu', false);
        this.$router.push({ name: clickName });
      },
    },
  };
</script>

<style lang="scss" scoped>
  %BG {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+48,ffffff+75,f7f7f7+87,e8e8e8+100 */
    background: rgb(255, 255, 255); /* Old browsers */
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 48%,
      rgba(255, 255, 255, 1) 75%,
      rgba(247, 247, 247, 1) 87%,
      rgba(232, 232, 232, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 48%,
      rgba(255, 255, 255, 1) 75%,
      rgba(247, 247, 247, 1) 87%,
      rgba(232, 232, 232, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 48%,
      rgba(255, 255, 255, 1) 75%,
      rgba(247, 247, 247, 1) 87%,
      rgba(232, 232, 232, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e8e8e8',GradientType=0 ); /* IE6-9 */
  }
  #DiGoHeader {
    height: 88px;
    @media screen and(max-width:768px) {
      height: 65px;
    }
    @extend %BG;
    .DiGoContainer {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      color: #6e6e6e;
      @extend %BG;
      @include setMaxWidth;
      @media screen and(max-width:768px) {
        justify-content: space-between;
      }
      .logo {
        width: fit-content;
        padding: 23px 35px;
        img {
          width: 120px;
          // height: 43.41px;
          @media screen and(max-width:768px) {
            width: 86px;
            // height: 31px;
          }
        }
      }
      .headerItem {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15%;
        height: 100%;
        font-size: 1.25rem;
        letter-spacing: 1px;
        white-space: nowrap;
        font-weight: bold;
        &:last-child {
          width: calc(100% - 190px - 60%);
        }
        .left {
          width: 20%;
        }
        .right {
          width: 80%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          font-size: 0.75rem;
        }
        .topBlock {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          height: 50%;
          padding-bottom: 6px;

          img {
            padding-right: 6px;
          }
        }
        .bottomBlock {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          height: 50%;
          .loginBtn {
            padding: 7px 40px;
            color: white;
            background: transparent linear-gradient(180deg, #fd8249 0%, #e25311 100%) 0% 0%
              no-repeat padding-box;
            border: 1px solid #e55817;
            border-radius: 12px;
            max-width: 120px;
            cursor: pointer;
            &:hover {
              background: transparent linear-gradient(180deg, #ffa074 0%, #dd7342 100%) 0% 0%
                no-repeat padding-box;
            }
          }
        }
        .whiteBlock {
          width: 1px;
          height: 30px;
          background: #e6e6e6 0% 0% no-repeat padding-box;
        }

        .loginBlock {
          color: #153892;
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
          .topIcon {
            display: block;
            margin: 0 auto;
            margin-bottom: 5px;
            cursor: pointer;
          }
          .bottomText {
            font-size: 0.8em;
            white-space: nowrap;
            cursor: pointer;
          }
        }
      }
      .menuOpenBlock {
        margin-right: 16px;
        cursor: pointer;
      }
      .headerItemActive {
        color: #153892;
      }
    }
    @keyframes fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .MobileMenu {
      animation: fade 0.15s linear;
      width: 100%;
      background-color: #000000d6;
      height: calc(100% - 65px);
      position: absolute;
      display: flex;
      justify-content: flex-end;
      z-index: 999;
      .MobileMenuContainer {
        background-color: #516cba;
        width: 80%;
        top: 0;
        left: 0;
        overflow: hidden;
        padding: 14px 30px;
        .menuItem {
          color: white;
          font-size: 20px;
          text-align: right;
          font-weight: bold;
          cursor: pointer;
        }
        .menuItemSplit {
          width: 100%;
          height: 1px;
          background: #8f9fcf;
          margin: 15px 0;
        }
        .mobileNotLoginBlock {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          white-space: nowrap;
          .plzLoginTip {
            margin: 0px 7px;
            color: white;
            font-size: 16px;
          }
          .loginBtn {
            padding: 6px 14px;
            background: transparent linear-gradient(180deg, #4369c9 0%, #153892 100%) 0% 0%
              no-repeat padding-box;
            border: 1px solid #1b3e99;
            border-radius: 5px;
            font-size: 18px;
            color: white;
          }
        }
        .mobileLogout {
          color: #ffffff;
          font-size: 20px;
          border: 1px solid #ffffff;
          text-align: center;
          padding: 6px 0;
        }
      }
    }
  }
</style>
