import Papa from 'papaparse';
import { toDecimal } from '@/utils/utils';
export const getCSV = (filePath) => {
  return new Promise((resolve, reject) => {
    const arr = [];
    Papa.parse(filePath, {
      download: true,
      step: (row) => {
        arr.push(row.data);
      },
      complete: () => {
        resolve(
          arr
            .filter((it, index) => {
              return it[0] !== '0';
            })
            .map((it, index) => {
              if (index !== 0) {
                return it.reduce((sum, data, dataIndex) => {
                  let newData = data;
                  const keyNameList = [
                    'enable',
                    'id',
                    'type',
                    'name',
                    'price',
                    'minPrice',
                    'maxPrice',
                    'describe',
                  ];
                  // price 補0
                  if (dataIndex === 4) {
                    newData = toDecimal(data.replace(/,/g, ''));
                  }
                  if (dataIndex === 0 && data === '0') {
                    return sum;
                  }
                  return { ...{ [keyNameList[dataIndex]]: newData }, ...sum };
                }, {});
              }
              return it;
            })
        );
      },
    });
  });
};
