import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import VueCarousel from 'vue-carousel';
import axios from 'axios';
import { getCSV } from '@/utils/getCSV.js';
import * as URL from '@/URLconfig.js';
import * as Animate from '@/AnimateClass.js';
import * as message from '@/utils/messageHandler.js';
import * as utils from '@/utils/utils';

import './router/routerNavigationHandler';

import 'animate.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css';

Vue.prototype.$URL = URL;
Vue.prototype.$axios = axios;
Vue.prototype.$Ani = Animate;
Vue.prototype.$MSG = message;
Vue.prototype.$CSV = getCSV;
Vue.prototype.$lib = utils;

// Stone要求不希望看到控制台出現 unHandler promise的訊息
// 因此可在promise事件後加上.catch(this.$autoCatch)
// 若是自己handle promise catch事件則不用使用此函數
Vue.prototype.$autoCatch = function (err) {
  if (process.env.NODE_ENV === 'development') {
    console.error('Promise autoCatch error:', err);
  }
};

Vue.use(ElementUI);
Vue.use(VueCarousel);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
