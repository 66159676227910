import router from '@/router';
import store from '@/store';

// router跳轉前handler
router.beforeEach((to, from, next) => {
  const matchNameList = to.matched.map((it) => it.name);
  if (store.state.admin.userType === 'merchant') {
    // 沒權限
    if (matchNameList.indexOf('ConsoleUserManagement') !== -1) {
      next({ name: 'ConsoleHome' });
    } else {
      next();
    }
  } else {
    next();
  }
});
