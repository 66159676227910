import { getAccounts, supportChangePWD } from '@/api/admin/index';
import { autoAPI } from '@/api/promiseApiHandler';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAccounts(_store, val) {
      return autoAPI(getAccounts, val);
    },
    supportChangePWD(_store, val) {
      return autoAPI(supportChangePWD, val);
    },
  },
};
