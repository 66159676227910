<template>
  <div id="DiGoFooter">
    <div class="top">
      <div class="MainContainer">
        <img src="@/assets/base/footerBanner.png" alt="" />
      </div>
    </div>
    <div class="bottom">
      <div class="MainContainer">{{ `Copyright © 2023 ${appTitle}版权所有` }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['appTitle'])
  }
}
</script>

<style lang="scss" scoped>
  #DiGoFooter {
    .top {
      height: 66px;
      width: 100%;
      border-top: 2px solid #ebebeb;
      img {
        display: block;
        width: 75%;
        height: auto;
        @media (max-width: 768px) {
          width: 95%;
        }
      }
    }
    .bottom {
      height: 41px;
      width: 100%;
      background: #153892;
    }
    .MainContainer {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      @include setMaxWidth;
      margin: 0 auto;
    }
  }
</style>
