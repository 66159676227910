import Vue from 'vue';
import Vuex from 'vuex';
import admin from './modules/admin/admin';

Vue.use(Vuex);

const localStorageCarts = JSON.parse(localStorage.getItem('carts'));
const accounts = JSON.parse(localStorage.getItem('accounts'));
const isRememberLogin = JSON.parse(localStorage.getItem('isRememberLogin'));
let loginData = null;
if (isRememberLogin) {
  loginData = JSON.parse(localStorage.getItem('loginData'));
}
export default new Vuex.Store({
  modules: { admin },
  state: {
    isMobile: false,
    isLoading: false,
    carts: localStorageCarts || [],
    accounts: accounts || [],
    isRememberLogin: isRememberLogin || false,
    loginData: loginData || {
      isLogin: false,
      loginMail: '',
    },
    isHeaderClickLogin: false,
    isShowMobileTypeSelect: false,
    isShowMobileMenu: false,
  },
  getters: {
    appTitle: () => process.env.VUE_APP_TITLE || '爱买购'
  },
  mutations: {
    setShowMobileTypeSelect(state, newVal) {
      state.isShowMobileTypeSelect = newVal;
    },
    setShowMobileMenu(state, newVal) {
      state.isShowMobileMenu = newVal;
    },
    setIsMobile(state, newVal) {
      state.isMobile = newVal;
    },
    setLoading(state, newVal) {
      state.isLoading = newVal;
    },
    setIsRememberLogin(state, newVal) {
      state.isRememberLogin = newVal;
      localStorage.setItem('isRememberLogin', JSON.stringify(state.isRememberLogin));
    },
    setLoginData(state, newVal) {
      if (newVal === false) {
        // 直接登出
        state.loginData = {
          isLogin: false,
          loginMail: '',
        };
      } else {
        if (newVal?.isLogin === undefined) {
          console.error('setLoginData isLogin not pass');
          return;
        }
        if (newVal?.loginMail === undefined) {
          console.error('setLoginData loginMail not pass');
          return;
        }
        state.loginData = newVal;
      }
      localStorage.setItem('loginData', JSON.stringify(state.loginData));
    },
    isHeaderClickLogin(state, newVal) {
      state.isHeaderClickLogin = newVal;
    },
    addCarts(state, newVal) {
      const findRes = state.carts.find((it) => it.id === newVal.id);
      if (findRes) {
        findRes.sum = newVal.sum;
        findRes.message = newVal.message;
      } else {
        state.carts.push(newVal);
      }
      localStorage.setItem('carts', JSON.stringify(state.carts));
    },
    editCarts(state, newVal) {
      if (typeof newVal?.id === 'undefined') {
        console.error('editCarts id error:', newVal);
        return;
      }
      const findRes = state.carts.find((it) => it.id === newVal.id);
      if (findRes) {
        if (newVal?.sum) {
          findRes.sum = newVal.sum;
        }
        if (newVal?.message || newVal.message === '') {
          findRes.message = newVal.message;
        }
        localStorage.setItem('carts', JSON.stringify(state.carts));
      } else {
        console.error('edit error:', findRes, newVal);
      }
    },
    deleteCarts(state, newVal) {
      if (typeof newVal?.length === 'undefined') {
        console.error('deleteCarts newVal need array');
        return;
      }
      newVal.forEach((id) => {
        const findIndex = state.carts.findIndex((it) => it.id === id);
        if (findIndex !== -1) {
          state.carts.splice(findIndex, 1);
        } else {
          console.error('deleteCarts error,no id:', id);
        }
      });
      localStorage.setItem('carts', JSON.stringify(state.carts));
    },
  },
  actions: {
    addAccount(store, newVal) {
      return new Promise((resolve, reject) => {
        const findRes = store.state.accounts.find((it) => it.mail === newVal.mail);
        if (findRes) {
          return reject(new Error('-1'));
        } else {
          store.state.accounts.push(newVal);
          localStorage.setItem('accounts', JSON.stringify(store.state.accounts));
          return resolve();
        }
      });
    },
    findAccount(store, newVal) {
      return new Promise((resolve, reject) => {
        const findRes = store.state.accounts.find((it) => it.mail === newVal.mail);
        console.log('findRes:', findRes);
        if (findRes) {
          if (findRes.password === newVal.password) {
            return resolve();
          } else {
            return reject(new Error('密码错误'));
          }
        } else {
          return reject(new Error('无此信箱'));
        }
      });
    },
    findAccountDataByEmail(store, email) {
      return new Promise((resolve, reject) => {
        const findRes = store.state.accounts.find((it) => it.mail === email);
        console.log('findRes:', findRes);
        if (findRes) {
          resolve(findRes);
        } else {
          console.error('findAccountDataByEmail no this email', email);
          reject(new Error());
        }
      });
    },
    setAccountDataByEmail(store, { email, newPassword }) {
      return new Promise((resolve, reject) => {
        const findRes = store.state.accounts.find((it) => it.mail === email);
        if (findRes) {
          findRes.password = newPassword;
          localStorage.setItem('accounts', JSON.stringify(store.state.accounts));
          resolve();
        } else {
          console.error('setAccountDataByEmail no this email', email);
          reject(new Error());
        }
      });
    },
  },
});
